<template>
  <div class="lists" :class="{ active: isActive }">
    <div class="list-item" v-for="(item, index) in items" :key="`item-${index + 1}`">
      {{ item.title }}
      <div class="action-vdo" v-if="type === 'vdo'" @click="openVdo(item)">
        <v-icon color="primary">mdi-play-circle-outline</v-icon>
        <span>{{ $t("product.detail.seeVdo") }}</span>
      </div>
      <a
        :href="item.link || '#'"
        target="_blank"
        rel="noopener noreferrer"
        class="action-doc text-decoration-none"
        v-else
      >
        <v-icon color="primary">mdi-tray-arrow-down</v-icon>
        <span>{{ $t("product.detail.download") }}</span>
      </a>
    </div>
    <youtube-dialog :model.sync="youtubeDialog" :video-id="watchingId" />
  </div>
</template>

<script>
import { getIdFromUrl } from "vue-youtube"
/** Components */
import YoutubeDialog from "@/components/Global/YoutubeDialog.vue"

export default {
  data: () => ({
    youtubeDialog: false,
    watchingId: ""
  }),
  components: { YoutubeDialog },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    isActive: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    openVdo(item) {
      this.watchingId = getIdFromUrl(item.link)
      this.youtubeDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.lists {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  background: #f5f5f5;
  overflow: hidden;

  &:not(.active) {
    min-height: 0px;
    height: 0px;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.active {
    min-height: 70px;
    height: max-content;
    opacity: 1;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .list-item {
    display: flex;
    width: 100%;
    min-height: 70px;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
    border-bottom: 1px dotted #000;
    background: transparent;
    font-size: 16px;
    color: #000;

    &:last-of-type, &:last-child {
      border-bottom: 0px !important;
    }

    .action-vdo {
      display: flex;
      min-width: fit-content;
      width: fit-content;
      column-gap: 4px;
      color: #e95d0f;
      cursor: pointer;
    }

    .action-doc {
      display: flex;
      min-width: fit-content;
      width: fit-content;
      column-gap: 4px;
      color: #e95d0f;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1024px) {
    .list-item {
      font-size: 14px !important;
    }
  }
}
</style>