import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class GroupColor {
  @JsonProperty("group_color_id") id!: number

  @JsonProperty() title!: string

  public hover = false
}
