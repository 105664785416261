import { JsonProperty, Serializable } from "typescript-json-serializer"
import Accessory from "@/types/Product/Accessory"
import ProductCategoryVdo from "@/types/Product/ProductCategoryVdo"
import ProductCategoryDocs from "@/types/Product/ProductCategoryDocs"
import ProductCategoryProduct from "@/types/Product/ProductCategoryProduct"
import DiamondFile from "@/types/DiamondFile"

function transformNewLine(data: string): string {
  return data.replace(/\n/g, "<br/>")
}

@Serializable()
export default class ProductCategoryDetail {
  @JsonProperty("product_category_id")
  id!: number

  @JsonProperty()
  title!: string

  @JsonProperty("bio_short")
  shortBio!: string

  @JsonProperty("bio_long")
  longBio!: string

  @JsonProperty("product_ref")
  productRef!: string

  @JsonProperty({ type: Accessory })
  accessories!: Array<Accessory>

  @JsonProperty({ type: DiamondFile })
  files: Array<DiamondFile> = []

  @JsonProperty({ type: DiamondFile })
  banner?: DiamondFile

  @JsonProperty({ type: DiamondFile, name: "cover_product_category" })
  cover?: DiamondFile

  @JsonProperty("usage_type")
  usageType!: string

  @JsonProperty("link")
  link!: string

  @JsonProperty({ type: DiamondFile, name: "usage_file" })
  usageFile: DiamondFile = new DiamondFile()

  @JsonProperty({ type: ProductCategoryVdo })
  vdo: Array<ProductCategoryVdo> = []

  @JsonProperty({ type: ProductCategoryDocs })
  docs: Array<ProductCategoryDocs> = []

  @JsonProperty({ type: ProductCategoryProduct })
  products: Array<ProductCategoryProduct> = []
}
