





















































import { Component, PropSync, Prop, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import ProductCategoryProduct from "@/types/Product/ProductCategoryProduct"
import { Cookies } from "@/utils/cookies"

/** Components */
import ModalBase from "@/components/ModalBase.vue"
import ModalSettingPolicy from "@/components/Global/ModalSettingPolicy.vue"

interface productForCompare {
  id: number
  title: string
  filePath: string
  productCategoryId: number
  productCategoryName: number
  productGroupId: number
}

@Component({
  name: "CardProductAction",
  components: { ModalBase, ModalSettingPolicy }
})
export default class ProductCategoryFilterDrawer extends mixins(BreakpointMixin) {
  private isActive: boolean = false
  private isActiveSnack: boolean = false
  private isActiveModal: boolean = false
  private isProcess: boolean = false
  private message: string = "";

  @Prop() readonly ["image"]!: string;
  @Prop() readonly ["title"]!: string;
  @Prop() readonly ["id"]!: Number;
  @Prop() readonly ["productCateId"]!: Number;
  @Prop() readonly ["productCateName"]!: String;
  @Prop() readonly ["productGroupId"]!: Number;
  @Prop() readonly ["product"]!: ProductCategoryProduct

  private openSlider() {
    if (this.product.additionalFiles && this.product.additionalFiles.length > 0) {
      this.isActive = true
    }
  }

  private compare() {
    if (Cookies.getCookieByKey(Cookies.acceptFuctional)) {
      if (this.getCompare.dimensions.length === 3) {
        this.$store.commit("setMsgSnack", this.messageMaxLength)
        this.$store.commit("toggleSnack", true)
        return
      }

      if (
        this.getCompare.dimensions.length > 0 &&
        this.getCompare.groupId &&
        `${this.getCompare.groupId}` !== `${this.productGroupId}`
      ) {
        this.$store.commit("setMsgSnack", this.messageNotMatchGroup)
        this.$store.commit("toggleSnack", true)
        return
      }

      if (
        this.getCompare.dimensions.map((dim: productForCompare) => dim.id).includes(this.product.id) &&
        this.productGroupId === this.getCompare.groupId
      ) {
        this.$store.commit("setMsgSnack", this.messageAlready)
        this.$store.commit("toggleSnack", true)
        return
      }

      this.$store.dispatch("setCompareItems", {
        id: this.product.id,
        title: this.product.title,
        filePath: this.product.image,
        productCategoryId: this.productCateId,
        productCategoryName: this.productCateName,
        productGroupId: this.productGroupId
      })
    } else {
      if (this.$store.state.isProcess) return
      this.$store.commit("setIsProcess", true)
      this.$store.commit("setMsgSnack", this.messageRequireAcceptCookie)
      this.$store.commit("toggleSnack", true)

      setTimeout(() => {
        this.$store.commit("toggleSnack", false)
        this.isActiveModal = true
        this.$store.commit("setIsProcess", false)
      }, 2000)
    }
  }

  private close() {
    this.isActiveSnack = false
  }

  private submitSetting() {
    this.isActiveModal = false
  }

  private get imageHeight() {
    const width = window.screen.width
    return width > 1440 ? "636px" : width > 1280 ? "424px" : width < 991 ? "155.04px" : "424px"
  }

  private get imageWidth() {
    const width = window.screen.width
    return width > 1440 ? 1271 : width > 1280 ? 848 : width < 991 ? 310 : 850
  }

  private get btnHeight() {
    const width = window.screen.width
    return width > 1440 ? "100px" : width > 1280 ? "86px" : width < 991 ? "26px" : "86px"
  }

  private get messageNotMatchGroup() {
    return `${this.$t("product.detail.messageNotMatchGroup")}`
  }

  private get messageMaxLength() {
    return `${this.$t("product.detail.messageMaxLength")}`
  }

  private get messageAlready() {
    return `${this.$t("product.detail.messageAlready")}`
  }

  private get messageRequireAcceptCookie() {
    return `${this.$t("cookiesPolicy.msgRequired")}`
  }

  private get getCompare() {
    const dimensions = localStorage.getItem("dimensions") || null
    const _dimensions = this.$store.state.dimensions.length
      ? this.$store.state.dimensions
      : dimensions
      ? JSON.parse(dimensions)
      : []
    return {
      groupId: this.$store.state.groupId || localStorage.getItem("groupId") || null,
      dimensions: _dimensions,
      isActive: _dimensions.length > 0
    }
  }

  created() {
    this.$store.commit("setIsProcess", false)
  }
}
