import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class ProductCategoryVdo {
  @JsonProperty("product_category_vdo_id")
  id!: number

  @JsonProperty()
  title!: string

  @JsonProperty("link")
  link!: string
}
